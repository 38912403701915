"use client";

import { type HTMLProps, type ReactNode } from "react";
import { Pacifico } from "next/font/google";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { Button } from "@/components/input/Button";
import denise from "public/images/denise.png";
import george from "public/images/george.png";
import joey from "public/images/joey.png";
import pelicanparty from "public/images/pelicanparty.jpg";
import pelicanbg from "public/images/smithislandpelican1.jpeg";
import sunset from "public/images/sunset3.jpeg";
import { twMerge } from "tailwind-merge";

import { useHomePageModalContext } from "../home/HomePageModal";

const pacifico = Pacifico({
  weight: "400",
  variable: "--font-pacifico",
  subsets: ["latin"],
});

const Divider = () => <hr className="rounded border border-gray-300" />;

const Header = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <h1 className={twMerge(" text-4xl font-bold", className)}>{children}</h1>
  );
};

const Header2 = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <h4 className={twMerge(" text-2xl font-bold text-gray-500", className)}>
      {children}
    </h4>
  );
};

const Paragraph = ({ children }: { children: ReactNode }) => {
  return <p className="space text-sm font-medium text-gray-400">{children}</p>;
};

const Image = (props: HTMLProps<HTMLImageElement>) => {
  return <img {...props} />;
};

const Container = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const outer = twMerge("flex w-full items-center justify-center", className);
  return (
    <div className={outer}>
      <div className="w-full rounded-xl border bg-violet-50 p-6 shadow-2xl">
        {children}
      </div>
    </div>
  );
};

const AboutPageContent = () => {
  const hide = useHomePageModalContext((ctx) => ctx.hide);
  const pathname = usePathname();
  const router = useRouter();
  const handleClicks = () => {
    if (pathname !== "/") router.push("/");
    hide();
  };

  return (
    <div className="bg-white">
      <div
        className="flex flex-col items-center justify-center bg-cover bg-center px-5 py-16 lg:py-36"
        style={{ backgroundImage: `url(${sunset.src})` }}
      >
        <div className="max-w-5xl">
          <Header className="text-center text-white  md:text-8xl">
            Embrace the Joy of Eco-Friendly Gifting
          </Header>
          <Header2 className="pt-2 text-center text-xl font-medium  text-slate-100 md:pt-4 md:text-4xl">
            Celebrate Life&apos;s Moments Sustainably
          </Header2>
          <div className="flex justify-center">
            <Header2 className="max-w-2xl pb-6 pt-2 text-center text-lg font-normal text-white">
              Discover the perfect blend of emotion and eco-consciousness with
              Wrap.Gifts. Send an eCard that treasures the planet as much as
              your loved ones.
            </Header2>
          </div>

          <Button
            className="w-full max-w-6xl py-6 text-3xl"
            size={"lg"}
            onClick={handleClicks}
          >
            Start Celebrating!
          </Button>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center px-5 py-4 md:px-8 md:pt-8">
        <div className="grid max-w-6xl justify-center md:grid-cols-10 md:gap-12">
          <div className="flex flex-col justify-center gap-4 md:col-span-6">
            <Container>
              <Header className="pb-1">Our eCards</Header>
              <Header2 className="pb-1.5">A World of Digital Artistry</Header2>
              <Paragraph>
                Dive into our diverse gallery of animated eCards, crafted for
                every occasion. Each card is a unique creation, combining
                artistic flair with personal touches, ready to brighten
                anyone&apos;s day.
              </Paragraph>
            </Container>
            <Button size="lg" onClick={handleClicks}>
              Explore eCards Now
            </Button>
          </div>
          <div className="order flex justify-center md:col-span-4 md:justify-start">
            <Image src={denise.src} width={400} alt="denise" />
          </div>
        </div>
      </div>
      <div className="mx:px-8 flex flex-col items-center justify-center px-5  md:pt-16">
        <div className="grid max-w-6xl justify-center md:grid-cols-10 md:gap-12">
          <div className="order-last flex  justify-center pt-4 md:order-first md:col-span-4 md:justify-start md:pt-0">
            <Image
              src={george.src}
              width={400}
              alt="george"
              className="rounded-xl shadow-2xl"
            />
          </div>
          <div className="flex flex-col justify-center gap-4 md:col-span-6">
            <Container>
              <Header className="pb-1">Sustainability First</Header>
              <Header2 className="pb-1.5">Gifting with a Green Heart</Header2>
              <Paragraph>
                At Wrap.Gifts, our commitment to climate change is embodied in
                each card we create. We blend artistic creativity with
                sustainable practices, ensuring that each digital greeting you
                send not only delights but also supports eco-friendly values.
              </Paragraph>
            </Container>
            <Button size="lg" onClick={handleClicks}>
              Start Celebrating Sustainably
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center px-5 py-4 md:px-8 md:pt-16">
        <div className="grid max-w-6xl justify-center md:grid-cols-10 md:gap-12">
          <div className="flex flex-col justify-center gap-4 md:col-span-6">
            <Container>
              <Header className="pb-1">Join our Creative Community</Header>
              <Header2 className="pb-1.5">
                Contribute to a gifting revolution
              </Header2>
              <Paragraph>
                We will open an artist marketplace in 2024 - an opportunity for
                visual artists, writers and poets to feature and promote their
                creations. Become part of a community that values
                sustainability, creativity, and connection.
              </Paragraph>
            </Container>
            <Link
              href="https://forms.gle/2zPR3WVarE92b1sE9"
              target="_blank"
              className="w-full"
            >
              <Button size="lg" className="w-full">
                Get notified when The Artist Marketplace Opens
              </Button>
            </Link>
          </div>
          <div className="order-last flex justify-center pt-4 md:col-span-4 md:justify-start md:pt-0 ">
            <Image
              src={joey.src}
              width={400}
              alt="joey"
              className="rounded-xl shadow-2xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutPageContent;
